<template>
  <b-overlay
    :show="showOver"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div>
      <b-row>
        <b-col md="5">
          <b-card>
            <div class="section-title mb-2">
              <span class="font-weight-bolder">{{ $t("setRate") }}</span>
            </div>

            <div class="row match-width">
              <div
                class="col-xl-4 col-lg-12 col-md-12 col-sm-12"
                :class="{ 'col-xl-12': isWideScreen }"
              >
                <div class="pre_rzf">
                  <div class="pre_rzf__title">
                    <span
                      style="font-size: 13px;"
                      class="text-nowrap"
                    >{{ $t("preOrder") }}</span>
                  </div>
                  <div class="pre_rzf__value">
                    {{ GenaralData.rate_preorder }}
                  </div>
                  <div class="pre_rzf__curent">
                    {{ $t("baht") }}
                  </div>
                </div>

                <b-form-input
                  v-model="GenaralData.rate_preorder"
                  type="number"
                  class="mt-2 text-center"
                />
              </div>

              <div
                class="col-xl-4 col-lg-12 col-md-12 col-sm-12"
                :class="{ 'col-xl-12': isWideScreen }"
              >
                <div class="pre_rzf">
                  <div class="pre_rzf__title">
                    <span
                      style="font-size: 13px;"
                      class="text-nowrap"
                    > {{ $t('key-325') }} </span>
                  </div>
                  <div class="pre_rzf__value">
                    {{ GenaralData.rate_import }}
                  </div>
                  <div class="pre_rzf__curent">
                    {{ $t("baht") }}
                  </div>
                </div>
                <b-form-input
                  v-model="GenaralData.rate_import"
                  type="number"
                  class="mt-2 text-center"
                />
              </div>

              <div
                class="col-xl-4 col-lg-12 col-md-12 col-sm-12"
                :class="{ 'col-xl-12': isWideScreen }"
              >
                <div class="pre_rzf">
                  <div class="pre_rzf__title">
                    <span
                      style="font-size: 13px;"
                      class="text-nowrap "
                    >{{ $t('alipay-1') }}</span>
                  </div>
                  <div class="pre_rzf__value">
                    {{ GenaralData.rate_payment }}
                  </div>
                  <div class="pre_rzf__curent">
                    {{ $t("baht") }}
                  </div>
                </div>
                <b-form-input
                  v-model="GenaralData.rate_payment"
                  type="number"
                  label="sd"
                  class="mt-2 text-center"
                />
              </div>

              <div class="col-12 mt-4">
                <button
                  class="btn btn-gradient-primary btn-block"
                  @click="submit()"
                >
                  {{ $t("saveData") }}
                </button>
              </div>
            </div>
          </b-card>

          <b-card>
            <div class="d-flex justify-content-between align-items-center mb-2">
              <div class="section-title mb-0">
                <span class="font-weight-bolder">{{ $t("letterhead") }}</span>
              </div>

              <a
                href="javascript:void(0)"
                @click="showHeadPaper"
              > {{ $t('edit') }} </a>
            </div>

            <div>
              <p>{{ $t("taxID") }} : {{ GenaralData.taxpayer }}</p>

              <b-form-group>
                <label for="incomeGreen">{{ $t("companyAddress") }} :</label>
                <b-form-textarea
                  id="incomeGreen"
                  v-model="GenaralData.company_address"
                  disabled
                />
              </b-form-group>
            </div>
          </b-card>

          <b-card>
            <div class="d-flex justify-content-between align-items-center mb-2">
              <div class="section-title mb-0">
                <span class="font-weight-bolder">{{ $t('key-182') }} </span>
              </div>

              <a
                href="javascript:void(0)"
                @click="$refs['modal-showLot'].show()"
              > {{ $t('edit') }} </a>
            </div>

            <div>
              <p class="text-center">
                <b-spinner
                  small
                  variant="danger"
                  type="grow"
                /> {{ $t('key-183') }}

                <b-spinner
                  small
                  variant="warning"
                  type="grow"
                /> {{ $t('key-184') }}
              </p>

              <b-form-group>
                <div
                  class="row match-height"
                  style="max-height:180px !important;overflow-y: auto !important;"
                >
                  <div
                    class="col-6 d-flex align-items-center"
                  >
                    <div
                      v-for="(item, index) in itemlotType1"
                      :key="index"
                    >
                      <b-badge
                        class="cursor-pointer ml-p1 w-100"
                        variant="danger"
                      >
                        {{ $t('truck') }}/{{ item.name_lot }}
                      </b-badge>
                    </div>
                  </div>
                  <div
                    class="col-6 d-flex align-items-center"
                  >
                    <div
                      v-for="(item, index) in itemlotType2"
                      :key="index"
                    >
                      <b-badge
                        class="cursor-pointer ml-p1 w-100 text-dark"
                        variant="warning"
                      >
                        {{ $t('ship') }}/{{ item.name_lot }}
                      </b-badge>

                    </div>
                  </div>
                </div>
                <!-- <div
                  v-for="(item, index) in itemlot"
                  :key="index"
                  class="mt-2"
                >
                  <b-badge
                    v-if="item.transportType === 1"
                    class="cursor-pointer ml-p1"
                    variant="danger"
                  >
                    EK{{ timelot() }}{{ item.number_lot }}
                  </b-badge>
                  <b-badge
                    v-if="item.transportType === 2"
                    class="cursor-pointer  ml-p1"
                    variant="warning"
                  >
                    SEA{{ timelot() }}{{ item.number_lot }}
                  </b-badge>

                </div> -->
              </b-form-group>
            </div>
          </b-card>
          <b-card>
            <div class="d-flex justify-content-between align-items-center mb-2">
              <div class="section-title mb-0">
                <span class="font-weight-bolder">{{ $t('key-249') }} </span>
              </div>
              <a
                href="javascript:void(0)"
                @click="$refs['modal-autoChat'].show()"
              > {{ $t('edit') }} </a>
            </div>
            <div>
              <b-form-group>
                <label
                  for="incomeGreen"
                >{{ $t('key-250') }}
                </label>
                <!-- <div class="float-right">
                  <b-form-checkbox
                    checked="true"
                    name="check-button"
                    switch
                    inline
                    class="custom-control-success"
                  >
                    เปิด/ปิด ใช้งาน
                  </b-form-checkbox>
                </div> -->
                <b-form-textarea
                  id="incomeGreen"
                  v-model="GenaralData.chat_auto"
                  class="mt-1"
                  disabled
                />
              </b-form-group>
            </div>
          </b-card>
        </b-col>

        <b-col md="7">
          <b-card>
            <div class="d-flex justify-content-between align-items-center mb-2">
              <div class="section-title mb-0">
                <span class="font-weight-bolder">{{ $t("dashboard") }}</span>
              </div>

              <a
                href="javascript:void(0)"
                @click="showDashboard"
              > {{ $t('edit') }} </a>
            </div>

            <div>
              <p>
                {{ $t("calculateCratePricing") }} :{{
                  GenaralData.cal_woodencrate
                }}
              </p>
              <p>
                {{ $t("chinaWarehouseAddress") }} :
                {{ GenaralData.chi_warehouse_address }}
              </p>
              <p>
                {{ $t("chinaWarehouseZip") }} :
                {{ GenaralData.chi_warehouse_zipcode }}
              </p>
              <p>
                {{ $t("chinaWarehousePhone") }} :
                {{ GenaralData.chi_warehouse_tel }}
              </p>
              <p class="mb-0">
                {{ $t("thailandWarehousePhone") }} :
                {{ GenaralData.thai_warehouse_tel }}
              </p>
            </div>
          </b-card>

          <b-card>
            <div class="d-flex justify-content-between align-items-center mb-2">
              <div class="section-title mb-0">
                <span class="font-weight-bolder">{{
                  $t("additionalInfo")
                }}</span>
              </div>

              <a
                href="javascript:void(0)"
                @click="showOther"
              >
                {{ $t("edit") }}
              </a>
            </div>

            <div>
              <p>{{ $t("key-78") }} : {{ GenaralData.dep_pay_at }}</p>
              <p>
                {{ $t("taobaoDepositServiceFee") }} :
                {{ GenaralData.service_fee_taobao }}฿
              </p>
              <p>
                {{ $t("changeSenderNameFee") }} :
                {{ GenaralData.service_fee_change_sender }}฿
              </p>
              <p>
                {{ $t("allShippingChinaToThailand") }}: {{ GenaralData.point }}฿
                {{ $t('earnPoints') }} 1P
              </p>
              <p class="mb-0">
                {{ $t("commissionFee") }} : {{ GenaralData.commission }}%
              </p>
            </div>
          </b-card>

          <b-card>
            <div class="d-flex justify-content-between align-items-center mb-2">
              <div class="section-title mb-0">
                <span class="font-weight-bolder">{{
                  $t("notificationAnnouncement")
                }}</span>
              </div>

              <a
                href="javascript:void(0)"
                @click="showAnnouncement"
              >
                {{ $t("edit") }}
              </a>
            </div>

            <div>
              <label
                for="alert_slide"
              >{{ $t('key-251') }}
              </label>
              <b-form-textarea
                id="alert_slide"
                v-model="GenaralData.alert_slide"
                disabled
              />
              <b-form-group>
                <label
                  for="incomeGreen"
                >{{ $t('key-252') }} 1
                  <span>(<b-spinner
                    small
                    label="Small Spinner"
                    type="grow"
                    style="color: #fc374e"
                  />)</span>:</label>
                <b-form-textarea
                  id="incomeGreen"
                  v-model="GenaralData.alert_green"
                  disabled
                />
              </b-form-group>

              <b-form-group>
                <label
                  for="incomeYellow"
                >{{ $t('key-252') }} 2
                  <span>(<b-spinner
                    small
                    label="Small Spinner"
                    type="grow"
                    style="color: #36aeb3"
                  />)</span>:</label>
                <b-form-textarea
                  id="incomeYellow"
                  v-model="GenaralData.alert_yellow"
                  disabled
                />
              </b-form-group>

              <b-form-group>
                <label
                  for="incomeRed"
                >{{ $t('key-252') }} 3
                  <span>(<b-spinner
                    small
                    label="Small Spinner"
                    type="grow"
                    style="color: #162d59"
                  />)</span>:</label>
                <b-form-textarea
                  id="incomeRed"
                  v-model="GenaralData.alert_red"
                  disabled
                />
              </b-form-group>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <!-- modal dashboard -->
      <b-modal
        id="modal-dashboard"
        title="แดชบอร์ด"
        ok-title="บันทึกข้อมูล"
        cancel-title="ยกเลิก"
        cancel-variant="outline-primary"
        @ok="submitDashboard()"
      >
        <b-form-group>
          <label for="emailTranfer">{{ $t("calculateCratePricing") }} :</label>
          <b-form-input
            id="emailTranfer"
            v-model="GenaralData.cal_woodencrate"
            type="text"
            :placeholder="$t('calculateCratePricing')"
          />
        </b-form-group>

        <b-form-group>
          <label for="addrCN">{{ $t("chinaWarehouseAddress") }} :</label>
          <b-form-textarea
            id="addrCN"
            v-model="GenaralData.chi_warehouse_address"
            :placeholder="$t('chinaWarehouseAddress')"
          />
        </b-form-group>

        <b-form-group>
          <label for="zipCN">{{ $t("chinaWarehouseZip") }} :</label>
          <b-form-input
            id="zipCN"
            v-model="GenaralData.chi_warehouse_zipcode"
            type="number"
            :placeholder="$t('chinaWarehouseZip')"
          />
        </b-form-group>

        <b-form-group>
          <label for="telCN">{{ $t("chinaWarehousePhone") }} :</label>
          <b-form-input
            id="telCN"
            v-model="GenaralData.chi_warehouse_tel"
            type="number"
            :placeholder="$t('chinaWarehousePhone')"
          />
        </b-form-group>

        <b-form-group>
          <label for="telTH">{{ $t("thailandWarehousePhone") }} :</label>
          <b-form-input
            id="telTH"
            v-model="GenaralData.thai_warehouse_tel"
            type="number"
            :placeholder="$t('thailandWarehousePhone')"
          />
        </b-form-group>
      </b-modal>

      <!-- modal other -->
      <b-modal
        id="modal-other"
        :title="$t('other')"
        :ok-title="$t('saveData')"
        :cancel-title="$t('cancel')"
        cancel-variant="outline-primary"
        @ok="submitOther()"
      >
        <b-form-group>
          <label for="emailTranfer">{{ $t("key-78") }} :</label>
          <b-form-input
            id="emailTranfer"
            v-model="GenaralData.dep_pay_at"
            type="text"
            :placeholder="$t('key-78')"
          />
        </b-form-group>

        <b-form-group>
          <label for="serviceFee">{{ $t("taobaoDepositServiceFee") }} :</label>
          <b-form-input
            id="serviceFee"
            v-model="GenaralData.service_fee_taobao"
            type="number"
            :placeholder="$t('taobaoDepositServiceFee')"
          />
        </b-form-group>

        <b-form-group>
          <label for="chengeNameFee">{{ $t("changeSenderNameFee") }} :</label>
          <b-form-input
            id="chengeNameFee"
            v-model="GenaralData.service_fee_change_sender"
            type="number"
            :placeholder="$t('changeSenderNameFee')"
          />
        </b-form-group>

        <b-form-group>
          <label for="chengeNameFee">{{ $t("key-79") }} :</label>
          <b-form-input
            id="chengeNameFee"
            v-model="GenaralData.point"
            type="number"
            :placeholder="$t('quantity')"
          />
        </b-form-group>

        <b-form-group>
          <label for="commission">{{ $t("commissionFee") }} :</label>
          <b-form-input
            id="commission"
            v-model="GenaralData.commission"
            type="number"
            :placeholder="$t('commissionFee')"
          />
        </b-form-group>
      </b-modal>

      <!-- modal announcement -->
      <b-modal
        id="modal-announcement"
        :title="$t('notificationAnnouncement')"
        :ok-title="$t('saveData')"
        :cancel-title="$t('cancel')"
        cancel-variant="outline-primary"
        @ok="submitAlert()"
      >

        <b-form-group>
          <label
            for="alert_slide"
          >{{ $t('key-251') }}
          </label>
          <b-form-textarea
            id="alert_slide"
            v-model="GenaralData.alert_slide"
          />
        </b-form-group>
        <b-form-group>
          <label
            for="incomeGreen"
          >{{ $t('key-252') }} 1
            <span>(<b-spinner
              small
              label="Small Spinner"
              type="grow"
              style="color: #fc374e"
            />)</span>:</label>
          <b-form-textarea
            id="incomeGreen"
            v-model="GenaralData.alert_green"
          />
        </b-form-group>

        <b-form-group>
          <label
            for="incomeYellow"
          >{{ $t('key-252') }} 2
            <span>(<b-spinner
              small
              label="Small Spinner"
              type="grow"
              style="color: #36aeb3"
            />)</span>:</label>
          <b-form-textarea
            id="incomeYellow"
            v-model="GenaralData.alert_yellow"
          />
        </b-form-group>

        <b-form-group>
          <label
            for="incomeRed"
          >{{ $t('key-252') }} 3
            <span>(<b-spinner
              small
              label="Small Spinner"
              type="grow"
              style="color: #162d59"
            />)</span>:</label>
          <b-form-textarea
            id="incomeRed"
            v-model="GenaralData.alert_red"
          />
        </b-form-group>
      </b-modal>

      <b-modal
        id="modal-head-paper"
        :title="$t('letterhead')"
        :ok-title="$t('saveData')"
        :cancel-title="$t('cancel')"
        cancel-variant="outline-primary"
        @ok="submitHeadPaper()"
      >
        <b-form-group>
          <label for="taxpayer">{{ $t("taxID") }} :</label>
          <b-form-input
            id="taxpayer"
            v-model="GenaralData.taxpayer"
            type="text"
            :placeholder="$t('taxID')"
          />
        </b-form-group>

        <b-form-group>
          <label for="company_address">{{ $t("companyAddress") }} :</label>
          <b-form-textarea
            id="company_address"
            v-model="GenaralData.company_address"
          />
        </b-form-group>
      </b-modal>

      <b-modal
        ref="modal-autoChat"
        :title="$t('key-249')"
        :ok-title="$t('key-13')"
        :cancel-title="$t('cancel')"
        size="md"
        @ok="submitChat()"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <label
                for="incomeGreen"
              >{{ $t('key-250') }}
              </label>
              <b-form-textarea
                id="incomeGreen"
                v-model="chat_auto"
                class="mt-1"
              />
            </b-form-group>
            <!-- <b-form-group>
              <label>ตั้งช่วงเวลา
              </label>

            </b-form-group> -->
          </b-col>
        </b-row>
      </b-modal>

      <b-modal
        ref="modal-showLot"
        :title="$t('key-257')"
        :ok-title="$t('key-13')"
        :cancel-title="$t('cancel')"
        size="lg"
        @ok="submitPrint()"
      >
        <b-row>
          <b-col md="6" />
          <b-col md="6">
            <b-form-group
              label-for="h-section"
              label-cols="3"
              label-cols-lg="3"
              label-cols-md="3"
            >
              <!-- {{ section }} -->
              <template v-slot:label>
                {{ $t('date') }} :
              </template>
              <flat-pickr
                v-model="dateNtim"
                class="form-control"
                :placeholder="$t('selectDateTime')"
              />
            </b-form-group>

          </b-col>

          <!-- <div class="header-cardx p-1 d-flex justify-content-between align-items-center"> -->
          <div class="d-flex align-items-center">
            <b-form-checkbox
              v-model="checkNothai"
              value="true"
              class="custom-control-primary ml-2"
              :indeterminate="isIndeterminate"
            />

          </div>

          <h6 class="mb-0">
            ไม่มีรายการตู้ที่เข้าไทย
          </h6>
        <!-- </div> -->

        </b-row>
        <div class="d-flex align-items-center mt-2">
          <div class="w-45">
            <div class="card border">
              <div class="header-cardx p-1 d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <b-form-checkbox
                    v-model="checkAll"
                    value="true"
                    class="custom-control-primary"
                    :indeterminate="isIndeterminate"
                    @change="checkAllOptions"
                  />
                  <h6 class="mb-0">
                    <span v-if="roleDetail.length">{{ roleDetail.length }}/</span>{{ optionsLeft.length }} items
                  </h6>
                </div>

                <h6 class="mb-0">
                  {{ $t('listlot') }}
                </h6>
              </div>

              <div class="p-1">
                <div class="income-order-search order-search">
                  <div class="anan-input search-input">
                    <div class="anan-input__inner anan-input__inner--normal">
                      <input
                        v-model="searchLeft"
                        type="text"
                        placeholder="Search here..."
                        class="anan-input__input"
                        @input="getLotOp()"
                      >
                      <div class="anan-input__suffix">
                        <i class="anan-input__clear-btn anan-icon" />
                        <i class="anan-input__suffix-icon anan-icon">
                          <i class="fal fa-search" />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="role-items">
                  <b-form-group class="mt-1">
                    <b-form-checkbox
                      v-for="option in filteredOptions"
                      :key="option.name_lot"
                      v-model="roleDetail"
                      :value="option._id"
                      name="flavour-3a"
                    >
                      <div class="mb-1">
                        {{ option.transportType === 1 ? $t('truck'):$t('ship') }}/{{ option.name_lot }}
                      </div>
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>

          <div class="w-10 p-50">
            <div>
              <button
                type="button"
                class="btn btn-primary btn-sm btn-icon-only w-100 mb-1"
                @click="moveToLeft"
              >
                <i class="fas fa-arrow-to-left" />
              </button>
            </div>
            <div>
              <button
                type="button"
                class="btn btn-primary btn-sm btn-icon-only w-100"
                @click="moveToRight"
              >
                <i class="fas fa-arrow-from-left" />
              </button>
            </div>
          </div>

          <div class="w-45">
            <div class="card border">
              <div class="header-cardx p-1 d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <b-form-checkbox
                    v-model="checkAll2"
                    value="true"
                    class="custom-control-primary"
                    :indeterminate="isIndeterminate2"
                    @change="checkAllOptions"
                  />
                  <h6 class="mb-0">
                    <span v-if="roleDetail2.length">{{ roleDetail2.length }}/</span>{{ optionsRight.length }} items
                  </h6>
                </div>

                <h6 class="mb-0">
                  {{ $t('selectlot') }}
                </h6>
              </div>

              <div class="p-1">
                <div class="income-order-search order-search">
                  <div class="anan-input search-input">
                    <div class="anan-input__inner anan-input__inner--normal">
                      <input
                        v-model="searchRight"
                        type="text"
                        placeholder="Search here..."
                        class="anan-input__input"
                        @input="getLotOp()"
                      >
                      <div class="anan-input__suffix">
                        <i class="anan-input__clear-btn anan-icon" />
                        <i class="anan-input__suffix-icon anan-icon">
                          <i class="fal fa-search" />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="role-items">
                  <b-form-group class="mt-1">
                    <b-form-checkbox
                      v-for="option in filteredOptions2"
                      :key="option.name_lot"
                      v-model="roleDetail2"
                      :value="option._id"
                      name="flavour-3a"
                    >
                      <div class="mb-1">
                        {{ option.transportType === 1 ? $t('truck'):$t('ship') }}/{{ option.name_lot }}
                        <!-- {{ option.transportType === 1 ? 'รถ':'เรือ' }}/{{ option.name_lot }} -->
                      </div>
                    </b-form-checkbox>
                  </b-form-group>
                </div>

              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>

  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BModal,
  BFormTextarea,
  BFormGroup,
  BOverlay,
  BSpinner,
  BBadge,
  BFormCheckbox,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    // BTable,
    // BLink,
    BRow,
    BCol,
    BFormInput,
    BModal,
    BFormTextarea,
    BFormGroup,
    BOverlay,
    BSpinner,
    BBadge,
    BFormCheckbox,
    flatPickr,
  },
  data() {
    return {
      search: '',
      isActive: 'all',
      preOreder: 5.1,
      importProduct: 5.1,
      deposit: 5.1,
      emailTranfer: '',
      serviceFee: '',
      chengeNameFee: '',
      commission: '',
      AutoMessage: null,
      timeMessageAuto_start: null,
      timeMessageAuto_end: null,
      GenaralData: {
        id: null,
        rate_preorder: 0,
        rate_import: 0,
        rate_payment: 0,
        cal_woodencrate: null,
        chi_warehouse_address: null,
        chi_warehouse_zipcode: null,
        chi_warehouse_tel: null,
        thai_warehouse_tel: null,
        dep_pay_at: null,
        service_fee_taobao: 0,
        service_fee_change_sender: 0,
        commission: 0,
        alert_green: null,
        alert_yellow: null,
        alert_red: null,
      },
      dateNtim: null,
      showOver: false,
      userData: JSON.parse(localStorage.getItem('userData')),
      itemlot: [],
      itemlotType1: [],
      itemlotType2: [],
      checkAll: false,
      checkAll2: false,
      roleDetail: [],
      roleDetail2: [],
      optionsRight: [],
      optionsLeft: [],
      searchLeft: '',
      searchRight: '',
      chat_auto: null,
      checkNothai: false,
      isWideScreen: false,
    }
  },
  computed: {
    isIndeterminate() {
      return this.roleDetail.length > 0 && this.roleDetail.length < this.optionsLeft.length
    },
    filteredOptions() {
      if (this.searchLeft) {
        const searchTerm = this.searchLeft.toLowerCase()
        return this.optionsLeft.filter(option => option.name_lot.toLowerCase().includes(searchTerm))
      }
      return this.optionsLeft
    },
    isIndeterminate2() {
      return this.roleDetail2.length > 0 && this.roleDetail2.length < this.optionsRight.length
    },
    filteredOptions2() {
      if (this.searchRight) {
        const searchTerm2 = this.searchRight.toLowerCase()
        return this.optionsRight.filter(option => option.name_lot.toLowerCase().includes(searchTerm2))
      }
      return this.optionsRight
    },
  },
  mounted() {
    this.getData('all')
    this.getLotOp()
    this.GetLottoDay()
    this.checkScreenWidth()
    window.addEventListener('resize', this.checkScreenWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenWidth)
  },
  methods: {
    checkScreenWidth() {
      this.isWideScreen = window.innerWidth >= 1200 && window.innerWidth <= 1670
    },
    async getLotOp() {
      try {
        const params = {
          searchRight: this.searchRight,
          searchLeft: this.searchLeft,
        }
        const { data: res } = await this.$http.get('/Lotorder/getLotIDThai', { params })
        this.optionsLeft = res.Left
        this.optionsRight = res.Right
        this.checkNothai = res.inthai.checkNothai
        this.dateNtim = res.inthai.lotdate
      } catch (e) {
        console.log(e)
      }
    },
    submitPrint() {
      const obj = {
        optionsRight: this.optionsRight,
        optionsLeft: this.optionsLeft,
        lotdate: this.dateNtim,
        checkNothai: this.checkNothai,
      }
      this.$http.post('/Lotorder/UpdateLotThai', obj)
        .then(() => {
          this.GetLottoDay()
          this.getLotOp()

          this.$refs['modal-showLot'].hide()
          this.Success('บันทึกข้อมูลเรียบร้อย')
        })
        .catch(err => console.log(err))
      // console.debug(this.optionsRight)
      // this.showOver = true
      // eslint-disable-next-line no-underscore-dangle
    },
    moveToLeft() {
      // eslint-disable-next-line no-underscore-dangle
      this.optionsLeft = this.optionsLeft.concat(this.optionsRight.filter(option => this.roleDetail2.includes(option._id)))
      // eslint-disable-next-line no-underscore-dangle
      this.optionsRight = this.optionsRight.filter(option => !this.roleDetail2.includes(option._id))
      this.roleDetail2 = []
      this.checkAll = false
      this.checkAll2 = false
    },
    moveToRight() {
      // eslint-disable-next-line no-underscore-dangle
      this.optionsRight = this.optionsRight.concat(this.optionsLeft.filter(option => this.roleDetail.includes(option._id)))
      // eslint-disable-next-line no-underscore-dangle
      this.optionsLeft = this.optionsLeft.filter(option => !this.roleDetail.includes(option._id))
      this.roleDetail = []
      this.checkAll = false
      this.checkAll2 = false
    },
    checkAllOptions() {
      if (this.checkAll) {
        // eslint-disable-next-line no-underscore-dangle
        this.roleDetail = this.optionsLeft.map(option => option._id)
      } else {
        this.roleDetail = []
      }
      if (this.checkAll2) {
        // eslint-disable-next-line no-underscore-dangle
        this.roleDetail2 = this.optionsRight.map(option => option._id)
      } else {
        this.roleDetail2 = []
      }
    },
    async GetLottoDay() {
      try {
        const { data: res } = await this.$http.get('/Lotorder/Tothai')
        // this.itemlot = res
        const itemlotType1 = res.data.filter(item => item.transportType === 1)
        const itemlotType2 = res.data.filter(item => item.transportType === 2)
        this.itemlotType1 = itemlotType1
        this.itemlotType2 = itemlotType2
      } catch (err) {
        console.log(err)
      }
    },
    timelot() {
      // moment.locale('th')
      return moment().tz('Asia/Bangkok').add(543, 'year').format('YY')
    },
    async getData() {
      this.showOver = true
      try {
        const params = {
          agent_id: this.userData.agent_id,
        }
        const { data: res } = await this.$http.get('/general/show', { params })
        if (res.status) {
          this.GenaralData = res.data
          this.chat_auto = this.GenaralData.chat_auto
        }
        this.showOver = false
      } catch (error) {
        console.log(error)
        this.showOver = false
      }
    },
    submitChat() {
      const obj = {
        id: this.GenaralData.id,
        chat_auto: this.chat_auto,
      }
      this.$http.post('general/update_auto', obj)
        .then(() => {
          this.getData()
          this.Success(this.$t('key-260'))
        })
        .catch(err => {
          console.log(err)
        })
      // this.chat_auto
      // console.debug(this.GenaralData)
    },
    showDashboard() {
      this.$bvModal.show('modal-dashboard')
    },
    showOther() {
      this.$bvModal.show('modal-other')
    },
    showAnnouncement() {
      this.$bvModal.show('modal-announcement')
    },
    showHeadPaper() {
      this.$bvModal.show('modal-head-paper')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    submit() {
      console.log(this.GenaralData)
      const obj = {
        id: this.GenaralData.id,
        agent_id: this.userData.agent_id,
        rate_preorder: Number(this.GenaralData.rate_preorder),
        rate_import: Number(this.GenaralData.rate_import),
        rate_payment: Number(this.GenaralData.rate_payment),
      }
      this.$http
        .post('/general/store_update', obj)
        .then(() => {
          this.$bvToast.toast('บันทึกข้อมูลสำเร็จ', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
        })
        .catch(error => {
          console.log(error)
          // this.SwalError(error.response.data.message)
        })
    },
    submitDashboard() {
      console.log('abc')
      const obj = {
        id: this.GenaralData.id,
        cal_woodencrate: this.GenaralData.cal_woodencrate,
        chi_warehouse_address: this.GenaralData.chi_warehouse_address,
        chi_warehouse_zipcode: this.GenaralData.chi_warehouse_zipcode,
        chi_warehouse_tel: this.GenaralData.chi_warehouse_tel,
        thai_warehouse_tel: this.GenaralData.thai_warehouse_tel,
      }
      this.$http
        .post('/general/woodencrate', obj)
        .then(() => {
          this.$bvToast.toast('บันทึกข้อมูลสำเร็จ', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
        })
        .catch(error => {
          console.log(error)
          // this.SwalError(error.response.data.message)
        })
    },
    submitOther() {
      const obj = {
        id: this.GenaralData.id,
        dep_pay_at: this.GenaralData.dep_pay_at,
        service_fee_taobao: this.GenaralData.service_fee_taobao,
        service_fee_change_sender: this.GenaralData.service_fee_change_sender,
        commission: this.GenaralData.commission,
        point: this.GenaralData.point,
      }
      this.$http
        .post('/general/service_update', obj)
        .then(() => {
          this.$bvToast.toast('บันทึกข้อมูลสำเร็จ', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
        })
        .catch(error => {
          console.log(error)
          // this.SwalError(error.response.data.message)
        })
    },
    submitAlert() {
      const obj = {
        id: this.GenaralData.id,
        alert_green: this.GenaralData.alert_green,
        alert_yellow: this.GenaralData.alert_yellow,
        alert_red: this.GenaralData.alert_red,
        alert_slide: this.GenaralData.alert_slide,
      }
      this.$http
        .post('/general/alert_update', obj)
        .then(() => {
          this.getData()
          this.$bvToast.toast('บันทึกข้อมูลสำเร็จ', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
        })
        .catch(error => {
          console.log(error)
          // this.SwalError(error.response.data.message)
        })
    },
    submitHeadPaper() {
      const obj = {
        id: this.GenaralData.id,
        taxpayer: this.GenaralData.taxpayer,
        company_address: this.GenaralData.company_address,
      }
      this.$http
        .post('/general/headpaper_update', obj)
        .then(() => {
          this.$bvToast.toast('บันทึกข้อมูลสำเร็จ', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
        })
        .catch(error => {
          console.log(error)
          // this.SwalError(error.response.data.message)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.ml-p1{
  margin-right: 5px;
  margin-bottom: 5px;
}

@media (min-width: 1200px) and (max-width: 1700px) {
  .text-nowrap {
    font-size: 11px !important; /* ปรับขนาดตัวหนังสือสำหรับหน้าจอที่เล็กขึ้น */
  }
}
</style>
